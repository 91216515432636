'use client';

import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { LanguageContext } from './serverLanguageContext';

export function ClientLanguageProvider({
  children,
  initialLocale = 'en',
}: {
  children: React.ReactNode;
  initialLocale: string;
}) {
  const [locale, setLocale] = useState(initialLocale);

  const handleSetLocale = (newLocale: string) => {
    setLocale(newLocale);
    Cookies.set('NEXT_LOCALE', newLocale);
  };

  return (
    <LanguageContext.Provider value={{ locale, setLocale: handleSetLocale }}>
      {children}
    </LanguageContext.Provider>
  );
}
